import '../assets/css/profileedit.css';
import "../assets/css/contact.css"
import Formsy from 'formsy-react';
import { navigate, Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';


import Layout from '../components/layout';
// import LeftProfile from '../components/leftProfile';
import MyInput from '../components/MyInput';
import SEO from '../components/seo';
import { setCredentials } from './../core/auth';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import closeChat from '../images/close-icon.svg';

import * as actionCreators from './../store/actions';

// import newslatter from "../images/newslatter.svg"
//import contactcloud from "../images/contacts/cloud.svg"
// import { Link } from "gatsby";
class VoidContractPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            userToken:null,
            profile:null,
            successMsg:null,
            respStatus:0,
            contractId:null,
            vin:null,
        };
    }

    async componentDidMount() {
        if (!isAuthenticated()) {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        if(this.props.location.state && this.props.location.state.contractId){
            this.setState({ contractId: this.props.location.state.contractId, vin: this.props.location.state.vin})
        } else {
           navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        let usrdata = getAuthenticatedUser();
        this.setState({ userToken: usrdata.token })
        //await this.getFromDirect(usrdata.token);
    }

    getFromDirect = async(paramId) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ profile: res.me })
                } else {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showLoader, hideLoader } = this.props;
        showLoader();
        
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/voidContract'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                token : this.state.userToken,
                contractId : this.state.contractId,
                reason : model.reason
            })
            })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 1){
                this.setState({ successMsg: 'Your contract voided Successfully','respStatus':1})
            } else if(res.status === 0){
                    this.setState({ successMsg: 'Void transaction Failed','respStatus':2 })
            } else if(res.status === -1) {
                setCredentials({});
                navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
            }
            //this.refs['voidForm'].reset();
            hideLoader();
        });

        
    }

    render() {
        const { canSubmit, successMsg,contractId,vin } = this.state;
        return (
            <Layout layoutType="profileHeader">
                <SEO title="Servicecontract.com" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                
                <section className={global.dealertoken?"formpart grayish":"formpart"}>
                    <div className="container">
                        
                        <div className="contact-form">
                            <Formsy ref="voidForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                {/* <ErrrorHandel formName={'voidForm'} self={this} /> */}
                                <div className="left-side">
                                    <h1>Void Contract <img onClick={() => navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="userclose" src={closeChat} alt="" /></h1>
                                    <div className="input-type">
                                        <MyInput
                                            name="contractId"
                                            id="contractId"
                                            placeholder="Contract ID"
                                            type="text"
                                            value={'Contract : '+ contractId}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                            disabled={true}
                                        />
                                        
                                        <MyInput
                                            name="vin"
                                            id="vin"
                                            placeholder="Your VIN"
                                            type="text"
                                            value={'VIN : ' +vin}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                            disabled={true}
                                        />
                                        <MyInput
                                            name="reason"
                                            id="textarea"
                                            placeholder="Enter reason for void"
                                            type="text"
                                            value=""
                                            validations={{
                                                minLength: 10
                                            }}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <p>{successMsg}</p>
                                    {/* {respStatus==0?<div className="info1"></div>:''}
                                    {respStatus==1?<div className="info2"><img src={tickIcon} />  </div>:''}
                                        {respStatus==2?<div className="info3"><img src={closeIcon} /> {successMsg} </div> :''} */}
                                    
                                    <div className="button-container change-pass-container-btn">
                                        <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled change-pass-btn' : 'btn change-pass-btn'}>
                                            Submit
                                        </button>
                                        <Link className="btn change-pass-btn outline" to={"/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Back</Link>
                                    </div>
                                    </div>
                            </Formsy>
                            
                        </div>
                    </div>
                </section>

                <section className="copyright-container last-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidContractPage);

